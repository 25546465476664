import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";

/**
 * @param {object, type ,prcieType}
 * type 0 为真实 非0为文案虚拟值
 * @returns {string | number}
 */
export function formatCouponPrice(data) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var prcieType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'coupon_price';
  console.log("data:", data); // 单品券和官方平台券支持折抵扣、折扣类型

  var price = '';

  if ([1, 3].includes(data.type) && !type) {
    if (data.resource_type === 8) {
      price = "\u7ACB\u51CF".concat(data.coupon_price);
    } else if ([2, 3, 4].includes(data.resource_type)) {
      if (data.cps_coupon_price >= data.cps_coupon_explain_money) {
        price = "\u7ACB\u51CF".concat(data.cps_coupon_price);
      } else {
        price = "\u6EE1".concat(data.cps_coupon_explain_money, "\u51CF").concat(data.cps_coupon_price);
      }
    } else {
      if (data.discount_type === 1) {
        price = data[prcieType] || data.coupon_money;
      } else {
        price = "".concat(data.discount_ratio * 10, "\u6298");
      }
    }
  } else {
    var text = data.discount_text;
    var arr = text.data.split(',');

    switch (text.type) {
      case 1:
        price = "\u51CF".concat(arr[0], "\u5143");
        break;

      case 2:
        price = "\u6700\u9AD8\u51CF".concat(arr[0], "\u5143");
        break;

      case 3:
        price = "\u6700\u4F4E\u51CF".concat(arr[0], "\u5143");
        break;

      case 4:
        price = "\u6EE1".concat(arr[0], "\u51CF").concat(arr[1], "\u5143");
        break;

      case 5:
        price = "\u6253".concat(arr[0], "\u6298");
        break;

      case 6:
        price = "\u6700\u9AD8\u6253".concat(arr[0], "\u6298");
        break;

      case 7:
        price = "\u6700\u4F4E\u6253".concat(arr[0], "\u6298");
        break;

      case 8:
        price = "\u6EE1".concat(arr[0], "\u5143\u6253").concat(arr[1], "\u6298");
        break;

      default:
        price = 0;
    }
  }

  if (prcieType === 'discount_money' && data.discount_type === 1) price = price;
  return price;
}