var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commodityBtn" },
    [
      _c(
        "div",
        { staticClass: "search-term " },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.searchInfo,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "SPU ID:" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入ID",
                      onkeyup: "value=value.replace(/[^\\d]/g,'')"
                    },
                    model: {
                      value: _vm.searchInfo.goods_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "goods_id", _vm._n($$v))
                      },
                      expression: "searchInfo.goods_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "SPU 名称:" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.searchInfo.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "keyword", $$v)
                      },
                      expression: "searchInfo.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品类型:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.searchInfo.coupon_type,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "coupon_type", $$v)
                        },
                        expression: "searchInfo.coupon_type"
                      }
                    },
                    _vm._l(_vm.goodsOption, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态:" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { filterable: "", placeholder: "请选择商品状态" },
                      model: {
                        value: _vm.searchInfo.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "status", $$v)
                        },
                        expression: "searchInfo.status"
                      }
                    },
                    _vm._l(_vm.statusOption, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "库存:" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { filterable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.searchInfo.stock_status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "stock_status", $$v)
                        },
                        expression: "searchInfo.stock_status"
                      }
                    },
                    _vm._l(_vm.stockOption, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品牌:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择品牌",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": _vm.getBrands
                      },
                      model: {
                        value: _vm.searchInfo.brand_ids,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "brand_ids", $$v)
                        },
                        expression: "searchInfo.brand_ids"
                      }
                    },
                    _vm._l(_vm.brands, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类目:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择类目",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": _vm.getCategories
                      },
                      model: {
                        value: _vm.searchInfo.category_ids,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "category_ids", $$v)
                        },
                        expression: "searchInfo.category_ids"
                      }
                    },
                    _vm._l(_vm.categories, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "关联SKU ID/名称:", "label-width": "130px" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.specKeyword,
                      callback: function($$v) {
                        _vm.specKeyword = $$v
                      },
                      expression: "specKeyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openDialog }
                    },
                    [_vm._v("新增")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit(0)
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.onReset } }, [
                    _vm._v("重置")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit(1)
                        }
                      }
                    },
                    [_vm._v("导出商品")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.batchVisible = true
                        }
                      }
                    },
                    [_vm._v("批量操作")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, stripe: "", "tooltip-effect": "dark" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { label: "SPU ID", prop: "id", width: "90px" }
          }),
          _c("el-table-column", {
            attrs: { label: "图片", prop: "logo", width: "120px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: { src: scope.row.logo, fit: "contain" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "130px", label: "SPU 标题", prop: "name" }
          }),
          _c("el-table-column", {
            attrs: { width: "100px", label: "关联SKU ID", prop: "specs" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm._f("handleSpec")(scope.row.specs))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "80px", label: "商品类型", prop: "coupon_type" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.coupon_type === 1
                      ? _c("span", [_vm._v("直充")])
                      : scope.row.coupon_type === 2
                      ? _c("span", [_vm._v("卡券")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "120px", label: "有效期（时）", prop: "validity" }
          }),
          _c("el-table-column", {
            attrs: { width: "90px", label: "库存数量", prop: "stock" }
          }),
          _c("el-table-column", {
            attrs: { width: "90px", label: "原价(元)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatPrice")(scope.row.sale_price))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "100px", label: "基础佣金(元)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatPrice")(scope.row.base_commission)
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "80px", label: "当前状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 1
                      ? _c("span", { attrs: { type: "success" } }, [
                          _vm._v("上架")
                        ])
                      : scope.row.status === -1
                      ? _c("span", { attrs: { type: "danger" } }, [
                          _vm._v("下架")
                        ])
                      : scope.row.status === 2
                      ? _c("span", { attrs: { type: "info" } }, [
                          _vm._v("售罄")
                        ])
                      : _c("span", { attrs: { type: "info" } }, [
                          _vm._v("未知")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "110px", label: "默认优惠券", prop: "coupons" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.formatMoney(scope.row)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "small", plain: "" },
                            on: {
                              click: function($event) {
                                return _vm.relationCoupon(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatMoney(scope.row)) +
                                "优惠券"
                            )
                          ]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.relationCoupon(scope.row)
                              }
                            }
                          },
                          [_vm._v("设置")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "200px",
              "before-close": _vm.handleClose
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary", plain: "" },
                        on: {
                          click: function($event) {
                            return _vm.updateEquityGoods(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary", plain: "" },
                        on: {
                          click: function($event) {
                            return _vm.renameSPU(scope.row)
                          }
                        }
                      },
                      [_vm._v("重命名")]
                    ),
                    scope.row.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.setEquityGoods(scope.row)
                              }
                            }
                          },
                          [_vm._v("下架")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.setEquityGoods(scope.row)
                              }
                            }
                          },
                          [_vm._v("上架")]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联优惠券",
            visible: _vm.dialogVisible,
            width: "70%",
            "before-close": _vm.beforeClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableDatas.coupons }
            },
            [
              _c("el-table-column", { attrs: { prop: "id", label: "券ID" } }),
              _c("el-table-column", {
                attrs: { prop: "internal_name", label: "优惠券名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "优惠券标题" }
              }),
              _c("el-table-column", {
                attrs: { prop: "discount_level", label: "优惠券档次" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.discount_level === 1
                          ? _c("span", [_vm._v("低")])
                          : scope.row.discount_level === 2
                          ? _c("span", [_vm._v("中")])
                          : scope.row.discount_level === 3
                          ? _c("span", [_vm._v("高")])
                          : _c("span", [_vm._v("未选择")])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "discount_money", label: "优惠券金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("handlePriceData")(scope.row)) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "circulation", label: "发放量" }
              }),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "库存" }
              }),
              _c("el-table-column", {
                attrs: { prop: "customer_income", label: "佣金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.customer_income / 100) + " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status === 1
                          ? _c("el-tag", [_vm._v("上架中")])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("已下架")
                            ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "默认优惠券" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949"
                          },
                          on: {
                            change: function($event) {
                              return _vm.setCoupons(
                                $event,
                                scope.$index,
                                scope.row
                              )
                            }
                          },
                          model: {
                            value: scope.row.is_default,
                            callback: function($$v) {
                              _vm.$set(scope.row, "is_default", $$v)
                            },
                            expression: "scope.row.is_default"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "SPU重命名", visible: _vm.renameVisible },
          on: {
            "update:visible": function($event) {
              _vm.renameVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "renameData",
              attrs: { model: _vm.renameData, "label-width": "80px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "SPU内部名称:" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "30", "show-word-limit": "" },
                    model: {
                      value: _vm.renameData.internal_name,
                      callback: function($$v) {
                        _vm.$set(_vm.renameData, "internal_name", $$v)
                      },
                      expression: "renameData.internal_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "SPU标题:" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "30", "show-word-limit": "" },
                    model: {
                      value: _vm.renameData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.renameData, "name", $$v)
                      },
                      expression: "renameData.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%", "margin-left": "40%" },
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.saveRename }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "SPU批量操作",
            visible: _vm.batchVisible,
            "before-close": _vm.handleBatchClose,
            width: "33%"
          },
          on: {
            "update:visible": function($event) {
              _vm.batchVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { padding: "10px 30px" },
                      on: { click: _vm.downloadTmp }
                    },
                    [_vm._v("批量模板（修改/创建）下载")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.path + "/fileUploadAndDownload/upload",
                        "file-list": _vm.fileList,
                        "on-success": _vm.handleSuccess,
                        "on-error": _vm.handleError,
                        "before-remove": _vm.handleRemove,
                        "on-exceed": _vm.handleExceed,
                        limit: 1,
                        multiple: ""
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { padding: "10px 30px" },
                          attrs: { loading: _vm.uploadLoading, type: "primary" }
                        },
                        [_vm._v("批量模板（修改/创建）上传")]
                      ),
                      _c("p", { staticClass: "upload-template-tips" }, [
                        _vm._v("模板上传一次最多支持50条哦")
                      ])
                    ],
                    1
                  ),
                  _vm.infoList.length
                    ? _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeNames,
                            callback: function($$v) {
                              _vm.activeNames = $$v
                            },
                            expression: "activeNames"
                          }
                        },
                        [
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "提示", name: 1 } },
                            _vm._l(_vm.infoList, function(item) {
                              return _c("p", { key: item }, [
                                _vm._v(_vm._s(item))
                              ])
                            }),
                            0
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("el-form-item", { staticStyle: { "text-align": "center" } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }