import service from '@/utils/request'; // @Tags ResourceCategory
// @Summary 创建EquityCategory
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceCategory true "创建EquityCategory"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityCategories/createEquityCategory [post]

export var createEquityCategory = function createEquityCategory(data) {
  return service({
    url: "/equityCategories/createEquityCategory",
    method: 'post',
    data: data
  });
}; // @Tags ResourceCategory
// @Summary 删除EquityCategory
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceCategory true "删除EquityCategory"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityCategories/deleteEquityCategory [delete]

export var deleteEquityCategory = function deleteEquityCategory(data) {
  return service({
    url: "/equityCategories/deleteEquityCategory",
    method: 'delete',
    data: data
  });
}; // @Tags ResourceCategory
// @Summary 删除EquityCategory
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除EquityCategory"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityCategories/deleteEquityCategory [delete]

export var deleteEquityCategoryByIds = function deleteEquityCategoryByIds(data) {
  return service({
    url: "/equityCategories/deleteEquityCategoryByIds",
    method: 'delete',
    data: data
  });
}; // @Tags ResourceCategory
// @Summary 更新EquityCategory
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceCategory true "更新EquityCategory"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /equityCategories/updateEquityCategory [put]

export var updateEquityCategory = function updateEquityCategory(data) {
  return service({
    url: "/equityCategories/updateEquityCategory",
    method: 'put',
    data: data
  });
}; // @Tags ResourceCategory
// @Summary 用id查询EquityCategory
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceCategory true "用id查询EquityCategory"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /equityCategories/findEquityCategory [get]

export var findEquityCategory = function findEquityCategory(params) {
  return service({
    url: "/equityCategories/findEquityCategory",
    method: 'get',
    params: params
  });
}; // @Tags ResourceCategory
// @Summary 分页获取EquityCategory列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取EquityCategory列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityCategories/getEquityCategoryList [get]

export var getEquityCategoryList = function getEquityCategoryList(params) {
  return service({
    url: "/equityCategories/getEquityCategoryList",
    method: 'get',
    params: params
  });
};