import service from '@/utils/request'; // @Tags EquityCommodity
// @Summary 创建EquityCommodity
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityCommodity true "创建EquityCommodity"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /resource/goods [post]

export var createEquityCommodity = function createEquityCommodity(data) {
  return service({
    url: "/resource/goods",
    method: 'post',
    data: data
  });
}; // @Tags EquityGoods
// @Summary 删除EquityGoods
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoods true "删除EquityGoods"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityGoods/deleteEquityGoods [delete]

export var deleteEquityGoods = function deleteEquityGoods(data) {
  return service({
    url: "/equityGoods/deleteEquityGoods",
    method: 'delete',
    data: data
  });
}; // @Tags EquityGoods
// @Summary 删除EquityGoods
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除EquityGoods"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityGoods/deleteEquityGoods [delete]

export var deleteEquityGoodsByIds = function deleteEquityGoodsByIds(data) {
  return service({
    url: "/equityGoods/deleteEquityGoodsByIds",
    method: 'delete',
    data: data
  });
}; // @Tags EquityCommodity
// @Summary 更新EquityCommodity
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityCommodity true "更新EquityCommodity"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /resource/goods [put]

export var updateEquityCommodity = function updateEquityCommodity(data) {
  return service({
    url: "/resource/goods",
    method: 'put',
    data: data
  });
}; // @Tags EquityGoods
// @Summary 用id查询EquityGoods
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoods true "用id查询EquityGoods"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /equityGoods/findEquityGoods [get]

export var findEquityGoods = function findEquityGoods(params) {
  return service({
    url: "/equityGoods/findEquityGoods",
    method: 'get',
    params: params
  });
}; // @Tags EquityCommodity
// @Summary 分页获取EquityCommodity列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取EquityCommodity列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /resource/goods [get]

export var getEquityCommodityList = function getEquityCommodityList(params) {
  return service({
    url: "/resource/goods",
    method: 'get',
    params: params
  });
}; // @Tags EquityCoupons
// @Summary 设置EquityCoupons
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityCoupons true "创建EquityCoupons"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /resource/coupon/default [post]

export var setEquityCoupons = function setEquityCoupons(data) {
  return service({
    url: "/resource/coupon/default",
    method: 'post',
    data: data
  });
};